import React from 'react';

import * as styles from './not-found.scss';

const NotFound: React.FunctionComponent<Record<string, unknown>> = () => {
  const code = 404;
  const title = 'Not Found';
  const description = 'the page you were looking for not exist or was moved to another location.';

  return (
    <div className="row">
      <div className="col-12">
        <h2 className={styles.code}>{code}</h2>
        <h1 className={styles.title}>{title}</h1>
        <blockquote>{description}</blockquote>
      </div>
    </div>
  );
};

export default NotFound;
